@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
    html {
        font-size: 16px;
        @media screen(md) {
            font-size: 16px;
        }
    }
}

h1 {
    @apply text-2xl font-bold;
}

h2 {
    @apply text-xl  font-bold;
}

h3 {
    @apply text-lg  font-bold;
}

:root {
    --regularFontWeight: 400;
    --mediumFontWeight: 500;
    --semiBoldFontWeight: 600;
    --boldFontWeight: 700;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

html,
body {
    max-width: 100vw;
}

body {
    color: rgb(var(--foreground-rgb));
    background: linear-gradient(
            to bottom,
            transparent,
            rgb(var(--background-end-rgb))
    ) rgb(var(--background-start-rgb));
}

/*html {*/
/*    font-size: 16px;*/

/*    @media screen and (max-width: 1200px) {*/
/*        font-size: 16px;*/
/*    }*/

/*    @media screen and (max-width: 900px) {*/
/*        font-size: 15px;*/
/*    }*/

/*    @media screen and (max-width: 600px) {*/
/*        font-size: 15px;*/
/*    }*/
/*}*/
input {
    touch-action: none;
    font-size: 16px;
    -webkit-text-size-adjust: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

p {
    padding: 0;
    margin: 0;
}

p::selection {
    background: #11f185;
    color: white;
}

p::-moz-selection {
    background: #099250;
    color: white;
}

img::selection {
    background: #11f185;
    color: white;
}

img::-moz-selection {
    background: #099250;
    color: white;
}

input {
    font-family: inherit;
}

button {
    font-family: inherit;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
}

.sylndr_button {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    outline: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-player__shadow {
    .react-player__play-icon {
        display: none !important;
    }
}

.primary {
    background-color: #009b5d;
    border: solid 1px #009b5d;
}

.secondary {
    background-color: #e6fbf2;
    border: solid 1px #e6fbf2;
    color: #00673e;
}

.inverted {
    background-color: #002246;
    border: solid 1px #002246;
}

.disabled {
    background-color: rgb(234, 236, 240);
    cursor: not-allowed;
    border: solid 1px rgb(234, 236, 240);
}

.scrollable::-webkit-scrollbar {
    width: 8px;
}

.scrollable::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #eaecf0;
}

/* width */
::-webkit-scrollbar {
    width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px grey;
    border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #eaecf0;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: lightgray;
}

@media only screen and (max-width: 850px) {
    ::-webkit-scrollbar {
        width: 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 8px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: none;
        border-radius: 2px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: none;
    }
}

.strikethrough {
    position: relative;
}

.strikethroughAr {
    position: relative;
}

.strikethrough:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1.5px solid;
    border-color: inherit;

    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
}

.strikethroughAr:before {
    position: absolute;
    content: '';
    left: 0;
    top: 32%;
    right: 0;
    border-top: 1.5px solid;
    border-color: inherit;

    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
}

/*
@TODO :: MAADO
imported from this link to be used with dynamic import later
https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css*/

.slick-list,
.slick-slider,
.slick-track {
    position: relative;
    display: block;
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: 0;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    top: 0;
    left: 0;
}

.slick-track:after,
.slick-track:before {
    display: table;
    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

/*
@TODO :: MAADO
imported from this link to be used with dynamic import later
https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0ajax-loader.gif/slick-theme.min.css*/

.slick-dots,
.slick-next,
.slick-prev {
    position: absolute;
    display: block;
    padding: 0;
}

.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
    /*font-family: slick;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-loading .slick-list {
    background: #fff;
}

/*@font-face {*/
/*    font-family: slick;*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*    src: url(fonts/slick.eot);*/
/*    src: url(fonts/slick.eot?#iefix) format('embedded-opentype'), url(fonts/slick.woff) format('woff'), url(fonts/slick.ttf) format('truetype'), url(fonts/slick.svg#slick) format('svg')*/
/*}*/

.work-slider {
    justify-content: center;
}

.slick-next,
.slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0;
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
    opacity: 1;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
    opacity: 0.25;
}

.slick-next:before,
.slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: #fff;
}

.slick-prev {
    left: -25px;
}

[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}

.slick-prev:before {
    content: '←';
}

.slick-next:before,
[dir='rtl'] .slick-prev:before {
    content: '→';
}

.slick-next {
    right: -25px;
}

[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}

[dir='rtl'] .slick-next:before {
    content: '←';
}

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    bottom: -25px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
}

.slick-dots li button:focus,
.slick-dots li button:hover {
    outline: 0;
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
    opacity: 1;
}

.slick-dots li button:before {
    font-size: 30px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 0.25;
    color: #000;
}

.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #000;
}

.pac-container {
    z-index: 3939393939399;
}

.direction-ltr {
    direction: ltr;
}

.shimmer {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;

    background: linear-gradient(
            100deg,
            rgba(255, 255, 255, 0) 20%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%
    );

    /*animation: shimmer 2s infinite linear;*/
}

/*
remove due to performance degrade on safari mobile.
*/
/*@keyframes shimmer {*/
/*    from {*/
/*        transform: translateX(-200%);*/
/*    }*/
/*    to {*/
/*        transform: translateX(200%);*/
/*    }*/
/*}*/
@keyframes twoLineSwitch1st {
    0% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-28px);
    }
    75% {
        transform: translateY(-28px);
    }
    100% {
        transform: translateY(-56px);
    }
}

@keyframes twoLineSwitch2nd {
    0% {
        transform: translateY(56px);
    }
    25% {
        transform: translateY(56px);
    }
    50% {
        transform: translateY(28px);
    }
    75% {
        transform: translateY(28px);
    }
    100% {
        transform: translateY(0px);
    }
}

.slndr-hidden {
    display: none !important;
}

@media screen and (max-width: 850px) {
    .visible-desktop-only {
        display: none;
    }
}

@media screen and (min-width: 850px) {
    .visible-mobile-only {
        display: none;
    }
}

.bnr-ex-bg {
    background-image: url("../../public/Images/ExchangeCarBannerBackground.svg");
}

.ar .nw-tag {
    background-image: url("../../public/Images/NewlyAddedAr.svg");
}

.en .nw-tag {
    background-image: url("../../public/Images/NewlyAddedEn.svg");
}

.scrollbar-hide {
    scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.car-pre-loading {
    background-image: url("../../public/Images/imgPlaceholder.gif");
    background-size: contain;
    background-repeat: no-repeat;
}

.car-pre-loading::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

/*@TODO :: bug need to be fixed */
#finance-slider span.MuiSlider-thumb {
    @apply ar:mr-[-5%];
}

& .MuiSlider-markLabel {
    font-size: 10px;
    font-weight: 500;
    color: #D0D5DD;
}

/*'& .MuiSlider-markLabelActive': {*/
/*                                  fontSize: '10px',*/
/*                                  fontWeight: 500,*/
/*                                  color: '#D0D5DD',*/
/*                              },*/

.link-car-card {
    @apply flex flex-col relative w-full h-full border  cursor-pointer bg-gray-50 no-underline rounded-xl border-solid border-gray-200 shadow-[0px_1px_3px_rgba(16,24,40,0.1),0px_1px_2px_rgba(16,24,40,0.06)];
}

.car-card-container {
    @apply h-full flex-1 flex flex-col p-3;
}

.car-card-title {
    @apply leading-6 text-secondary-500 opacity-100 text-start block w-full;
}

.car-card-trim-container {
    @apply text-gray-500 opacity-100 text-start mb-1;
}

.car-card-info-container {
    @apply flex items-baseline has-discount:flex no-discount:hidden;
}

.car-listing {
    @apply overscroll-none;
}

/** CAR LISTING RANGE SLIDER*/
.range-slider-vertical-control-input {
    border: 0px;
    clip: rect(0px, 0px, 0px, 0px);
    height: 100%;
    margin: -1px;
    overflow: hidden;
    padding: 0px;
    position: absolute;
    white-space: nowrap;
    width: 100%;
    direction: rtl;
}

.range-slider-vertical-control:after {
    content: '';
    @apply border border-solid border-primary-600
    h-6 flex items-center justify-center w-6 rounded-full bg-white
    cursor-pointer;
}

.range-slider-vertical-control-tool-tip-cont {
    @apply border border-solid border-gray-300 absolute ar:left-[-125px] en:right-[-125px] top-1/2 z-20 ml-3 -translate-y-1/2
    whitespace-nowrap rounded-md bg-white
    py-2 px-5 text-xs leading-3 text-gray-800
}

.range-slider-vertical-control-tool-tip-inner {
    @apply absolute ar:-right-1.5 en:-left-1.5 top-1/2 -z-10 h-3 w-3
    border-gray-300 ar:border-t ar:border-r en:border-b en:border-l
    -translate-y-1/2 rotate-45
    bg-white
}

/** CAR LISTING RANGE SLIDER*/
